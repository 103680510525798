<template>
  <section
    :class="[
      $style.wrapper,
      {
        [$style.comment]: commentText,
      },
      {
        [$style.comment_type]: commentType !== 0 || commentReplyType !==0,
      },
      {
        [$style.read]: this.readFlg,
      },
    ]"
    :style="customStyle"
    @click="handleClick"
  >
    <img v-if="clip" :class="$style.clip" src="@/assets/images/clip.svg" />
    <img
      v-if="pin"
      :class="$style.pin"
      src="@/assets/images/favorite_pin.svg"
    />
    <div
      v-if="commentReplyType === 0 && commentType !== 0"
      :class="[
        $style.picture_wrapper,
        {
          [$style.comment_wrapper]: commentText,
        },
      ]"
    >
      <div
        v-if="
          commentText &&
          head !== 'ブロックしたユーザのコメント' &&
          commentType === 1
        "
      >
        <div
          :class="$style.text"
          :style="`background-color: rgba(${backgroundColor}, .2);`"
        >
          <div :class="$style.comment_info_wrapper">
            <div :class="$style.comment_info_inner_left">
              <div v-if="serviceId" :class="$style.sso_logo_wrapper">
                <img :src="sso_logo_imgpath(serviceId)" />
              </div>
              <router-link
                :to="'/home/profile/' + userId + '/'"
                :class="$style.avatar_link"
              >
                <div :class="$style.avatar_wrapper">
                  <base-avatar
                    size="30px"
                    :border-color="avatarColor"
                    :color="avatarColor"
                    :class="$style.avatar"
                    :path="avatarImage"
                  />
                  <div v-if="nickName" :class="$style.nick_name">
                    {{ nickName }}
                  </div>
                </div>
              </router-link>
              <p :class="$style.time">
                <time :datetime="timeAttr" :class="$style.time_text">{{
                  timeText
                }}</time>
              </p>
            </div>
            <div :class="$style.icons_area">
              <p :class="$style.icons">
                <span
                  :class="[
                    $style.icons_wrapper,
                    { [$style.selected]: !!selected },
                  ]"
                >
                  <span :class="$style.icons_inner">
                    <base-icon
                      v-if="isMentioned"
                      :type="iconType"
                      :name="iconAtName"
                      :color="iconAtColor"
                      :style="{ padding: '0 2px' }"
                      size="12px"
                    />
                  </span>
                  <span
                    :class="$style.icons_inner"
                    @click.stop="handleCommentGood"
                  >
                    <base-icon
                      :type="iconType"
                      :name="iconHeartName"
                      :color="this.goodFlg ? 'pink' : 'gray'"
                      size="12px"
                    />
                    <span>{{ goodCount }}</span>
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div :class="$style.comment_text" v-html="$sanitize(commentText)"></div>
        </div>
      </div>
      <div
        v-else-if="
          head !== 'ブロックしたユーザのコメント' &&
          (commentType === 2 || commentType === 4)
        "
      >
        <div
          :class="$style.text"
          :style="`background-color: rgba(${backgroundColor}, .2);`"
        >
          <div :class="$style.comment_info_wrapper">
            <div :class="$style.comment_info_inner_left">
              <div v-if="serviceId" :class="$style.sso_logo_wrapper">
                <img :src="sso_logo_imgpath(serviceId)" />
              </div>
              <router-link
                :to="'/home/profile/' + userId + '/'"
                :class="$style.avatar_link"
              >
                <div :class="$style.avatar_wrapper">
                  <base-avatar
                    size="30px"
                    :border-color="avatarColor"
                    :color="avatarColor"
                    :class="$style.avatar"
                    :path="avatarImage"
                  />
                  <div v-if="nickName" :class="$style.nick_name">
                    {{ nickName }}
                  </div>
                </div>
              </router-link>
              <p :class="$style.time">
                <time :datetime="timeAttr" :class="$style.time_text">{{
                  timeText
                }}</time>
              </p>
            </div>
            <div :class="$style.icons_area">
              <p :class="$style.icons">
                <span
                  :class="[
                    $style.icons_wrapper,
                    { [$style.selected]: !!selected },
                  ]"
                >
                  <span :class="$style.icons_inner">
                    <base-icon
                      v-if="isMentioned"
                      :type="iconType"
                      :name="iconAtName"
                      :color="iconAtColor"
                      :style="{ padding: '0 2px' }"
                      size="12px"
                    />
                  </span>
                  <span
                    :class="$style.icons_inner"
                    @click.stop="handleCommentGood"
                  >
                    <base-icon
                      :type="iconType"
                      :name="iconHeartName"
                      :color="this.goodFlg ? 'pink' : 'gray'"
                      size="12px"
                    />
                    <span>{{ goodCount }}</span>
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div :class="$style.comment_text">
            {{ commentText }}
            <picture :class="$style.file">
              <img :src="image" />
            </picture>
          </div>
        </div>
        <!-- <picture :class="$style.file">
          <img :src="image" />
        </picture> -->
      </div>
      <div
        v-else-if="head !== 'ブロックしたユーザのコメント' && commentType === 3"
      >
        <div
          :class="$style.text"
          :style="`background-color: rgba(${backgroundColor}, .2);`"
        >
          <div :class="$style.comment_info_wrapper">
            <div :class="$style.comment_info_inner_left">
              <div v-if="serviceId" :class="$style.sso_logo_wrapper">
                <img :src="sso_logo_imgpath(serviceId)" />
              </div>
              <router-link
                :to="'/home/profile/' + userId + '/'"
                :class="$style.avatar_link"
              >
                <div :class="$style.avatar_wrapper">
                  <base-avatar
                    size="30px"
                    :border-color="avatarColor"
                    :color="avatarColor"
                    :class="$style.avatar"
                    :path="avatarImage"
                  />
                  <div v-if="nickName" :class="$style.nick_name">
                    {{ nickName }}
                  </div>
                </div>
              </router-link>
              <p :class="$style.time">
                <time :datetime="timeAttr" :class="$style.time_text">{{
                  timeText
                }}</time>
              </p>
            </div>
            <div :class="$style.icons_area">
              <p :class="$style.icons">
                <span
                  :class="[
                    $style.icons_wrapper,
                    { [$style.selected]: !!selected },
                  ]"
                >
                  <span :class="$style.icons_inner">
                    <base-icon
                      v-if="isMentioned"
                      :type="iconType"
                      :name="iconAtName"
                      :color="iconAtColor"
                      :style="{ padding: '0 2px' }"
                      size="12px"
                    />
                  </span>
                  <span
                    :class="$style.icons_inner"
                    @click.stop="handleCommentGood"
                  >
                    <base-icon
                      :type="iconType"
                      :name="iconHeartName"
                      :color="this.goodFlg ? 'pink' : 'gray'"
                      size="12px"
                    />
                    <span>{{ goodCount }}</span>
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div :class="$style.comment_text">
            {{ commentText }}
            <picture :class="$style.stamp">
              <img :src="image" />
            </picture>
          </div>
        </div>
      </div>
      <div :class="$style.wrapper_talk" v-else>
        <picture :class="$style.picture">
          <span
            :class="$style.talk_label"
            :style="`border-left: 24px solid ${borderColor};`"
          ></span>
          <img :src="image" />
        </picture>
      </div>
    </div>
    <!-- ここからリプライ -->
    <div v-if="commentType === 0 && commentReplyType !== 0">
      <!-- リプライ対象 -->
      <div
          :class="[$style.text, $style.reply_target]"
          :style="`background-color: rgba(${backgroundColor}, .2);`"
        >
        <div :class="$style.comment_info_wrapper">
          <div :class="$style.comment_info_inner_left">
            <div v-if="serviceId" :class="$style.sso_logo_wrapper">
              <img :src="sso_logo_imgpath(serviceId)" />
            </div>
            <router-link
              :to="'/home/profile/' + userId + '/'"
              :class="$style.avatar_link"
            >
              <div :class="$style.avatar_wrapper">
                <base-avatar
                  size="30px"
                  :border-color="avatarColor"
                  :color="avatarColor"
                  :class="$style.avatar"
                  :path="replyTargetCreateUserImage"
                />
                <div v-if="replyTargetCreateUserName" :class="$style.nick_name">
                  {{ replyTargetCreateUserName }}
                </div>
              </div>
            </router-link>
            <p :class="$style.time_text">
              {{ replyTargetDate }}
            </p>
          </div>
        </div>
        <div :class="$style.comment_wrapper">
          <div
            v-if="replyTargetText"
          >
            <div :class="$style.comment_text" v-html="$sanitize(replyTargetText)"></div>
          </div>
          <div
            v-else-if="replyTargetImage"
          >
            <div :class="$style.comment_text">
              <picture :class="$style.file">
                <img :src="replyTargetImage" />
              </picture>
            </div>
          </div>
        </div>
      </div>
      <!-- リプライ本体 -->
      <div
        :class="$style.comment_reply_wrapper"
      >
        <div
          v-if="head !== 'ブロックしたユーザのコメント'"
        >
          <div
            :class="$style.text"
            :style="`background-color: rgba(${backgroundColor}, .2);`"
          >
            <div :class="$style.comment_info_wrapper">
              <div :class="$style.comment_info_inner_left">
                <div v-if="serviceId" :class="$style.sso_logo_wrapper">
                  <img :src="sso_logo_imgpath(serviceId)" />
                </div>
                <router-link
                  :to="'/home/profile/' + userId + '/'"
                  :class="$style.avatar_link"
                >
                  <div :class="$style.avatar_wrapper">
                    <base-avatar
                      size="30px"
                      :border-color="avatarColor"
                      :color="avatarColor"
                      :class="$style.avatar"
                      :path="avatarImage"
                    />
                    <div v-if="nickName" :class="$style.nick_name">
                      {{ nickName }}
                    </div>
                  </div>
                </router-link>
                <p :class="$style.time">
                  <time :datetime="timeAttr" :class="$style.time_text">{{
                    timeText
                  }}</time>
                </p>
              </div>
              <div :class="$style.icons_area">
                <p :class="$style.icons">
                  <span
                    :class="[
                      $style.icons_wrapper,
                      { [$style.selected]: !!selected },
                    ]"
                  >
                    <span :class="$style.icons_inner">
                      <base-icon
                        v-if="isMentioned"
                        :type="iconType"
                        :name="iconAtName"
                        :color="iconAtColor"
                        :style="{ padding: '0 2px' }"
                        size="12px"
                      />
                    </span>
                    <span
                      :class="$style.icons_inner"
                      @click.stop="handleCommentReplyGood"
                    >
                      <base-icon
                        :type="iconType"
                        :name="iconHeartName"
                        :color="this.goodFlg ? 'pink' : 'gray'"
                        size="12px"
                      />
                      <span>{{ goodCount }}</span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              v-if="commentReplyText && commentReplyType === 1"
            >
              <div :class="$style.comment_text" v-html="$sanitize(commentReplyText)"></div>
            </div>
            <div
              v-else-if="(commentReplyType === 2 || commentReplyType === 4)"
            >
              <div :class="$style.comment_text">
                {{ commentReplyText }}
                <picture :class="$style.file">
                  <img :src="image" />
                </picture>
              </div>
            </div>
            <div
              v-else-if="commentReplyType === 3"
            >
              <div :class="$style.comment_text">
                {{ commentReplyText }}
                <picture :class="$style.stamp">
                  <img :src="image" />
                </picture>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.wrapper_talk" v-else>
          <picture :class="$style.picture">
            <span
              :class="$style.talk_label"
              :style="`border-left: 24px solid ${borderColor};`"
            ></span>
            <img :src="image" />
          </picture>
        </div>
      </div>
    </div>
    <!-- ここまでリプライ -->
    <div :class="$style.wrapper_talk" v-if="commentType === 0 && commentReplyType === 0">
      <picture :class="$style.picture">
        <span
          :class="$style.talk_label"
          :style="`border-left: 24px solid ${borderColor};`"
        ></span>
        <img :src="image" />
      </picture>
    </div>
    <div :class="$style.info_wrapper">
      <div :class="$style.header_wrapper">
        <div :class="$style.header_inner_left">
          <div v-if="serviceId" :class="$style.sso_logo_wrapper">
            <img :src="sso_logo_imgpath(serviceId)" />
          </div>
          <router-link
            v-if="commentType === 0 && commentReplyType === 0"
            :class="$style.avatar_link"
            :to="'/home/profile/' + userId + '/'"
          >
            <div :class="$style.avatar_wrapper">
              <base-avatar
                size="30px"
                :border-color="avatarColor"
                :color="avatarColor"
                :class="$style.avatar"
                :path="avatarImage"
              />
              <div v-if="nickName" :class="$style.nick_name">
                {{ nickName }}
              </div>
            </div>
          </router-link>
          <div :class="$style.tag_wrapper">
            <base-tag
              v-if="labelText"
              width="max-content"
              height="14px"
              padding="5px"
              position="static"
              :bgColor="tagColor"
              :color="tagTextColor"
              fontSize="base"
              >{{ labelText }}</base-tag
            >
            <p :class="$style.time" v-if="!commentText && commentType !== 1 && !chatDate">
              <time :datetime="timeAttr" :class="$style.time_text">{{
                timeText
              }}</time>
            </p>
            <p v-else-if="chatDate" :class="$style.time_text">
              {{ chatDate }}
            </p>
          </div>
          <div :class="$style.team_name" v-if="teamName">
            {{ teamName }}
          </div>
        </div>

        <div :class="$style.icons_area" v-if="commentType === 0 && commentReplyType === 0">
          <p :class="$style.icons">
            <span
              :class="[$style.icons_wrapper, { [$style.selected]: !!selected }]"
            >
              <span
                :class="$style.icons_inner"
              >
                <base-icon
                  v-if="isMentioned"
                  :type="iconType"
                  :name="iconAtName"
                  :color="iconAtColor"
                  :style="{ padding: '0 2px' }"
                  size="12px"
                />
              </span>
              <span :class="$style.icons_inner" @click.stop="handleRead">
                <base-icon
                  :type="iconType"
                  :name="iconEyeName"
                  :color="this.readFlg ? 'green' : 'gray'"
                  size="12px"
                />
                <span>{{ readCount }}</span>
              </span>
              <span :class="$style.icons_inner">
                <base-icon
                  :type="iconType"
                  name="comment-dots"
                  :color="this.commentCount > 0 ? 'green' : 'gray'"
                  size="12px"
                />
                <span>{{ commentCount }}</span>
              </span>
              <span :class="$style.icons_inner" @click.stop="handleGood">
                <base-icon
                  :type="iconType"
                  :name="iconHeartName"
                  :color="this.goodFlg ? 'pink' : 'gray'"
                  size="12px"
                />
                <span>{{ goodCount }}</span>
              </span>
            </span>
          </p>
        </div>
      </div>
      <h3 :class="$style.head">{{ head }}</h3>
    </div>
  </section>
</template>

<script>
import BaseIcon from '../../../base/BaseIcon/BaseIcon'
import BaseTag from '../../../base/BaseTag/BaseTag'
import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar.vue'

export default {
  name: 'AppCarouselCommentNotificationItemWide',
  components: {
    BaseIcon,
    BaseTag,
    BaseAvatar,
  },
  props: {
    goodCount: {
      type: Number,
      default: 0,
    },
    goodFlg: {
      type: Boolean,
      default: null,
    },
    readCount: {
      type: Number,
      default: null,
    },
    commentCount: {
      type: Number,
      default: 0,
    },
    readFlg: {
      type: Boolean,
      default: null,
    },
    post: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
    avatarColor: {
      type: String,
      default: '',
    },
    avatarName: {
      type: String,
      default: '',
    },
    avatarImage: {
      type: String,
      default: '',
    },
    head: {
      type: String,
      default: '',
    },
    teamName: {
      type: String,
      default: 'チーム名が入ります',
    },
    iconHeart: {
      type: String,
      default: 'heart',
      validator: (icon) => !icon || ['heart'].includes(icon),
    },
    iconEye: {
      type: String,
      default: 'eye',
      validator: (icon) => !icon || ['eye'].includes(icon),
    },
    iconAt: {
      type: String,
      default: 'at',
      validator: (icon) => !icon || ['at'].includes(icon),
    },
    image: {
      type: String,
      default: require('@/assets/images/no-image.png'),
    },
    tag: {
      type: String,
      default: null,
    },
    customTagText: {
      type: String,
      default: null,
    },
    customTagColor: {
      type: String,
      default: null,
    },
    customTagTextColor: {
      type: String,
      default: null,
    },
    time: {
      type: [String, Date, Number],
      default: () => new Date(),
    },
    selected: {
      type: Boolean,
      default: null, // true:選択, false:非選択, null:選択機能無し
    },
    clip: {
      type: Boolean,
      default: false,
    },
    pin: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    category: {
      type: String,
      default: null,
    },
    steps: {
      type: Array,
      default: () => [],
      validator: (steps) => steps.every((step) => step >= 0 && step <= 100),
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    showRead: {
      type: Boolean,
      default: true,
    },
    showPin: {
      type: Boolean,
      default: true,
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    reported: {
      type: Boolean,
      default: false,
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showBrowse: {
      type: Boolean,
      default: false,
    },
    isCursorPointer: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '',
    },
    commentText: {
      type: String,
      required: false,
      default: '',
    },
    nickName: {
      type: String,
      required: false,
      default: '',
    },
    commentType: {
      type: Number,
      required: false,
      default: 0,
    },
    isMentioned: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: '#7BE293',
    },
    serviceId: {
      type: Number,
      default: null,
    },
    commentReplyType: {
      type: Number,
      required: false,
      default: 0,
    },
    commentReplyText: {
      type: String,
      required: false,
      default: '',
    },
    chatDate: {
      type: String,
      default: null,
    },
    replyTargetId: {
      type: Number,
      default: 0,
    },
    replyTargetText: {
      type: String,
      required: false,
      default: '',
    },
    replyTargetImage: {
      type: String,
      required: false,
      default: '',
    },
    replyTargetCreateUserName: {
      type: String,
      default: null,
    },
    replyTargetCreateUserImage: {
      type: String,
      default: null,
    },
    replyTargetMentionedUsers: {
      type: Array,
    },
    replyTargetDate: {
      type: String,
    },
  },
  methods: {
    sso_logo_imgpath(service_id) {
      try {
        const img = require(`@/assets/images/sso_logo_service_id_${service_id}.png`)
        return img
      } catch (e) {
        return ''
      }
    },
    handleRead() {
      // 未読/既読イベント
      this.$emit('read', this.item)
    },
    commentIconClick() {
      this.$emit('comment', this.chatId)
    },
    handleGood() {
      this.$emit('like', this.item)
    },
    handleCommentGood() {
      this.$emit('commentLike', this.item)
    },
    handleCommentReplyGood() {
      this.$emit('commentReplyLike')
    },
    handleClick() {
      // クリックイベントが発火するように設定
      this.$emit('click', this.item)
    },
    handleFavorite() {
      // お気に入りイベント
      this.$emit('favorite', this.item)
    },
    handlePin() {
      // ピンイベント
      this.$emit('pin', this.item) //user側の変更は文言でクリップと言いつつピンなので注意
    },
    handleDelete() {
      // 削除イベント
      this.$emit('delete', this.item)
    },
    handleShare() {
      // シェアイベント
      this.$emit('share', this.item)
    },
    handleReport() {
      // ユーザ通報イベント
      this.$emit('report', this.item)
    },
    handleBlock() {
      // ブロックイベント
      this.$emit('blockUser', this.item)
    },
    handleBrowse() {
      // 閲覧イベント
      this.$emit('browse', this.item)
    },
  },
  computed: {
    labelText() {
      const labelTextMap = {
        chat: '投稿',
        chat_comment: 'コメント',
        mini_vote_comment: 'コメント',
        mini_vote: '投票',
        comment_reply: 'コメント',
      }

      return labelTextMap[this.category] || null
    },
    ableToSelect() {
      return this.selected === false || this.selected === true
    },
    customStyle() {
      let style = {}

      if (this.isCursorPointer) {
        // trueの場合マウスオーバーでカーソルが指マークに変化
        style.cursor = 'pointer'
      }
      return style
    },
    iconType() {
      return 'fas' // iconやselectedによって変化する予定
    },
    iconAtName() {
      return this.iconAt
    },
    iconHeartName() {
      return this.iconHeart
    },
    iconEyeName() {
      return this.iconEye
    },
    iconAtColor() {
      return 'red'
    },
    iconHeartColor() {
      return this.selected ? 'pink' : 'gray'
    },
    timeAttr() {
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return date.toLocaleString()
    },
    timeText() {
      if (!this.time) {
        return ''
      }
      const date =
        this.time instanceof Date
          ? this.time
          : new Date(this.time.replace(/-/g, '/'))
      return `${date.getFullYear()}/${('0' + (date.getMonth() + 1)).slice(
        -2
      )}/${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(
        -2
      )}:${('0' + date.getMinutes()).slice(-2)}`
    },
    displayTag() {
      if (
        this.customTagText ||
        this.customTagTextColor ||
        this.customTagColor
      ) {
        return true
      } else if (this.tag) {
        return true
      }

      return false
    },
    tagColor() {
      if (this.customTagColor) {
        return this.customTagColor
      }

      const tagColorMap = {
        need: 'pink',
        step: 'yellow',
        done: 'yellow',
      }

      return tagColorMap[this.tag] || 'pink'
    },
    tagStepText() {
      const tagStepText = this.steps
        .map((step, i) => `Step${i + 1} - ${step}%`)
        .slice(0, 2)
        .join('  ')
      return tagStepText
    },
    tagText() {
      if (this.customTagText) {
        return this.customTagText
      }

      const tagTextMap = {
        need: '受講してください',
        step: this.tagStepText,
        done: '受講完了',
      }

      return tagTextMap[this.tag] || ''
    },
    tagTextColor() {
      if (this.customTagTextColor) {
        return this.customTagTextColor
      }

      const tagTextColorMap = {
        need: 'white',
        step: 'black',
        done: 'black',
      }

      return tagTextColorMap[this.tag] || 'white'
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  width: 100%;
  // height: 280px;
  height: auto;
  background-color: $keyWhite;
  box-sizing: border-box;
  border-bottom: 1px solid $borderGray;
  display: flex;
  padding: 20px 0;
  gap: 20px;
  @include mobile_only() {
    height: auto;
    flex-direction: column;
    gap: 10px;
    // width: 150px;
    // height: 160px;
  }
  &.comment,
  &.comment_type {
    flex-direction: column-reverse;
    gap: 16px;
    @include mobile_only() {
      gap: 10px;
    }
    .head {
      font-size: 14px;
      line-height: 1.75;
      font-weight: normal;
      @include mobile_only() {
        font-size: 12px;
      }
    }
  }
}
.wrapper.read {
  opacity: 50%;
}

.clip {
  position: absolute;
  top: -16px;
  left: 4px;
}

.pin {
  z-index: 2;
  position: absolute;
  top: -14px;
  left: -3px;
  transform: rotate(-21deg);
}
.header_wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.tag_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  // flex: 1;
  // max-width: 100px;
}

.time {
  display: flex;
  color: $fontGray;
  margin: 0px;
  // width: 142px;
}
.time_text {
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  text-align: left;
}
.icons_area {
  margin-left: auto;
  @include mobile_only() {
    display: none;
  }
}
.icons {
  display: inline-block;
  color: $borderLiteGray;
  margin: 0;

  &_wrapper {
    display: flex;
    @include mobile_only() {
      transform: scale(0.6);
    }
  }

  .selected {
    color: $keyPink;
  }
}

.icons span {
  float: right;
}

.overlay_menu_wrapper {
  display: flex;
  .menu_button_icon {
    width: 10px;
    height: 10px;
    cursor: pointer;
    background-image: url('../../../../assets/images/グループ 1656.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.picture {
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: auto;
  @include mobile_only() {
    // height: 120px;
    // text-align: left;
  }
}
// 202402修正
.picture > img {
  height: auto;
  max-width: 200px;
  max-height: 80%;
  @include mobile_only() {
  }
}

.stamp {
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 200px;
  @include mobile_only() {
    // height: 64px;
  }
}

.stamp > img {
  height: auto;
  max-width: 200px;
  max-height: 80%;
  object-fit: contain;
  @include mobile_only() {
    // margin: 4px;
  }
}

.file {
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 200px;
  @include mobile_only() {
    // height: 64px;
  }
}

.file > img {
  height: auto;
  max-width: 200px;
  max-height: 80%;
  object-fit: contain;
  @include mobile_only() {
    // margin: 4px;
  }
}

.comment_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  white-space: pre-wrap;
  @include mobile_only() {
    // margin: 4px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: $keyBlack;
  margin: 0;
  word-break: break-all;
  overflow: hidden;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.75;
  padding: 20px;
  @include mobile_only() {
    font-size: 10px;
    line-height: 12px;
    padding: 10px;
  }
}
.reply_target {
  margin-bottom: 15px;
}
.head {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $keyGreen;
  line-height: 1.5;
  font-size: 16px;
  word-break: break-all;
  overflow: hidden;
  margin: 0;
  padding-left: 0;
  font-weight: bold;
  text-decoration: underline;
  @include mobile_only() {
    line-height: 1.75;
    font-size: 12px;
  }
}

.foot {
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  color: $keyBlack;
  line-height: 1.75;
  font-size: 15px;
  word-break: break-all;
  overflow: hidden;
  position: absolute;
  bottom: 8px;
  @include mobile_only() {
    line-height: 1.75;
    font-size: 12px;
  }
}

.nick_name {
  display: block;
  font-weight: bold;
  line-height: 1.25;
  font-size: 12px;
  word-break: break-all;
  overflow: hidden;
  @include mobile_only() {
    font-size: 12px;
  }
}
// 202402追加

.avatar_wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  color: $keyBlack;
}
.avatar_link {
  text-decoration: none;
}

.label_wrap {
  font-size: 10px;
  line-height: 1;
  background-color: $keyGreen;
  color: $keyWhite;
  padding: 2px 5px;
  white-space: nowrap;
  border-radius: 2px;
}
.team_name {
  font-size: 12px;
  line-height: 1.5;
  color: $keyBlack;
  @include mobile_only() {
    line-height: 1.75;
    font-size: 12px;
  }
}
.info_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header_inner_left {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  @include mobile_only() {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
.icons_wrapper {
  gap: 10px;
}
.comment_wrapper {
  width: 100%;
}
.comment_info_wrapper {
  display: flex;
  justify-content: space-between;
}
.comment_reply_wrapper {
  width: 93%;
  margin-left: auto;
}

.comment_info_inner_left {
  display: flex;
  gap: 10px;
  align-items: center;
  @include mobile_only() {
    flex-direction: column;
  }
}
.avatar_wrapper {
  display: flex;
  gap: 6px;
}
.header_inner_left {
  display: flex;
  gap: 20px;
}
.icons_inner {
  display: flex;
  gap: 2px;
  align-items: center;
  font-size: 10px;
  color: $keyBlack;
}
.wrapper_talk {
  position: relative;
  width: 200px;
  .talk_label {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 24px solid transparent;
    border-left: 24px solid transparent;
    z-index: 3;
  }
  img {
    width: 200px;
    height: auto;
    max-width: 200px;
  }
}
</style>
