<template>
  <transition
    :name="$style.modal"
    :enter-class="$style.modal_enter"
    :enter-active-class="$style.modal_enter_active"
    :enter-to-class="$style.modal_enter_to"
    :leave-class="$style.modal_leave"
    :leave-active-class="$style.modal_leave_active"
    :leave-to-class="$style.modal_leave_to"
    @leave="closing"
    appear="appear"
  >
    <div :class="customClass" :style="modalPadding">
      <section
        :style="modalWidth"
        :class="[
          $style.modal_content,
          type === 'round' ? $style.modal_content_round : '',
          disableMobileWidth ? $style.modal_fix_width: ''
        ]"
      >
        <div v-if="type === 'square' && !hidenCloseBtn" :class="$style.modal_content_header">
          <h1 :class="$style.modal_content_header_title">
            <slot name="header-title"></slot>
          </h1>
          <div
            :class="$style.modal_content_header_close"
            @click.prevent.stop="$emit('close')"
          >
            <img src="@/assets/images/times.svg" alt="閉じる" />
          </div>
        </div>

        <div v-if="type === 'round' && !hidenCloseBtn">
          <div
            :class="$style.modal_content_header_close"
            @click.prevent.stop="$emit('close')"
          >
            <img src="@/assets/images/times.svg" alt="閉じる" />
          </div>
        </div>

        <div :class="bodyStyle" :style="minHeight">
          <div v-if="type === 'round' && !hideContentHeader" :class="$style.modal_content_header">
            <h1 :class="$style.modal_content_header_title">
              <slot name="header-title"></slot>
            </h1>
          </div>
          <div :class="$style.modal_content_body_inner">
            <slot name="body"></slot>
          </div>
        </div>

        <div v-if="this.$slots.footer" :class="$style.modal_content_footer">
          <slot name="footer"></slot>
        </div>
      </section>

      <div v-if="imageEnlargementFlg === true" :class="$style.modal_bg" @click.prevent.stop="$emit('close')"></div>
      <div v-else :class="$style.modal_bg" ></div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'BaseModal',
  inheritAttrs: false,
  components: {},
  props: {
    position: {
      type: String,
      default: 'center',
      validator: (value) => [undefined, 'center', 'top'].includes(value),
    },
    type: {
      type: String,
      default: 'square',
      validator: (value) => ['none', 'square', 'round'].includes(value),
    },
    width: {
      type: String,
      default: '800px',
    },
    height: {
      type: String,
      default: 'auto',
    },
    paddingTop: {
      type: String,
      default: undefined,
    },
    hidenCloseBtn: {
      type: Boolean,
      default: false,
    },
    hideContentHeader:{
      type: Boolean,
      default: false,
    },
    imageEnlargementFlg: {
      type: Boolean,
      default: false,
    },
    disableMobileWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customClass() {
      return [this.$style.modal, this.$style[`modal_position_${this.position}`]]
    },
    bodyStyle() {
      return [
        this.$style.modal_content_body,
        this.$slots.footer ? this.$style.modal_content_body_has_footer : '',
      ]
    },
    minHeight() {
      return {
        'min-height': this.height,
      }
    },
    modalWidth() {
      return {
        width: this.width,
      }
    },
    modalPadding() {
      return {
        'padding-top': this.paddingTop,
      }
    },
  },
  methods: {
    closing(el, done) {
      setTimeout(() => {
        done()
      }, 400)
    },
  },
}
</script>
<style lang="scss" module>
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.modal_position_center {
  align-items: center;
}

.modal_content {
  position: relative;
  top: 0;
  z-index: 1001;
  display: grid;
  max-height: 90%;
  grid-template:
    'modal-header' auto
    'modal-body' 1fr
    'modal-footer' auto / 1fr;
  transition: 0.4s;
  transition-delay: 0.2s;
  @include mobile() {
    width: 80% !important;
  }

  &.modal_fix_width {
    @include mobile() {
      width: 300px !important;
    }
  }
}

.modal_content_header {
  grid-area: modal-header;
  height: 52px;
  line-height: 52px;
  position: relative;
}
.modal_content_header_close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 4px;
  border-radius: 100px;
  right: 16px;
  cursor: pointer;
  background: #262626;
  width: 32px;
  height: 32px;
  border: 1px solid #707070;
  line-height: 0;
  vertical-align: middle;
  text-align: center;
}
.modal_content_header_title {
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.modal_content_body {
  grid-area: modal-body;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #707070;
}

.modal_content_body_has_footer {
  border-bottom: none;
}

.modal_content_body_inner {
}

.modal_content_footer {
  grid-area: modal-footer;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #707070;
  border-top: none;
}

.modal_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  transition: 0.4s;
}

.modal_enter .modal_content,
.modal_leave_to .modal_content {
  opacity: 0;
  top: -10px;
}

.modal_enter .modal_bg,
.modal_leave_to .modal_bg {
  opacity: 0;
}

.modal_enter_to .modal_content {
  opacity: 1;
  top: 0;
  .modal_bg {
    opacity: 1;
  }
}

.modal_enter_to .modal_bg {
  opacity: 1;
}

.modal_leave .modal_content {
  opacity: 1;
  top: 0;
}

.modal_leave .modal_bg {
  opacity: 1;
}

.modal_leave_active .modal_content {
  transition: 0.4s;
}

.modal_content_wrapper {
}

.modal_content_round {
  .modal_content_body {
    border: 0;
    border-radius: 10px;
  }
  .modal_content_body_has_footer {
    border-radius: 10px 10px 0 0;
  }
  .modal_content_footer {
    border: 0;
    border-radius: 0 0 10px 10px;
  }
  .modal_content_header_title {
    color: #000;
  }
  .modal_content_header_close {
    right: -10px;
    top: -10px;
    z-index: 10;
  }
}
</style>
