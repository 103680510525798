<template>
  <div class="$style.team_layout">
    <team-chat-detail-header
    :team-name="team_name"
    :teamId="Number(team_id)"
    :chatId="Number(chat_id)"
    :title="title"
    :back-url="back_url + team_id"
    :cover-image="cover_image"
    :block-user="blockUserFlg"
    :edit-chat-flg="editChatFlg"
    :delete-auth-flg="deleteAuthFlg"
    :type="type"
    :clip="!!pinFlg"
    :showPinFlg="blockUserFlg ? false : showPinFlg"
    :commentNoticeFlg="commentNoticeFlg"
    :mentionNoticeFlg="mentionNoticeFlg"
    :read="readFlg"
    :read-count="readCount"
    :favorite-flg="favoriteFlg"
    :spam-flg="spam_flg"
    :report_flg="report_flg"
    :is-before-approval="isBeforeApproval"
    :previousPost="this.previousPost"
    :previousType="this.previousType"
    :nextPost="this.nextPost"
    :nextType="this.nextType"
    @spam="headerMenuValue = 'spam'"
    @block="headerMenuValue = 'block'"
    @report="headerMenuValue = 'report'"
    @ref-data="reflectData"
    />
    <div :class="[$style.team_content, { [$style.hide_tabbar]: !showTabbar }]">
      <div :class="[$style.team_content_body, {[$style.team_content_body_loading]: showSpinner}]">
        <template v-if="teamErrorMsgList && teamErrorMsgList.length > 0">
          <transition :name="$style.team"
                      :enter-class="$style.team_enter"
                      :enter-active-class="$style.team_enter_active"
                      :leave-active-class="$style.team_leave_active"
                      :leave-to-class="$style.team_leave_to">
              <div :class="$style.msg_container">
                <base-message type="error" v-for="(error, i) in teamErrorMsgList" :key="i"
                :class="$style.msg_area">{{error.message}}</base-message>
              </div>
          </transition>
        </template>
        <!-- <div :class="$style.back_area" v-if="!showSpinner && !isMobile && showPcBackBtn && !onTheEdge" >
          <app-prev-button margin="10px 0" @click="$router.push(back)" />
        </div> -->
        <!-- 戻るボタンが左端に存在する場合 -->
        <!-- <div v-if="onTheEdge" >
          <app-prev-button margin="10px 0 0 33px" @click="$router.push(back)" />
        </div> -->
        <router-view v-show="!showSpinner" :header-menu-value="headerMenuValue" :result-obj="resultObj" @chat="callbackChat" @readClick="callbackRead" @executed="headerMenuValue = ''" @previous-and-next="fetchPreviousAndNext"/>
        <div v-if="showSpinner">
          <base-icon name="spinner" size="32px" spin />
        </div>
      </div>
    </div>
    <app-scroll-button />
    <div style="padding-bottom: 70px;">
      <app-footer v-if="showFooter" bgColor="yellow"/>
    </div>

    <base-modal
      v-if="showQrGrenerator"
      type="round"
      @close="showQrGrenerator = false"
    >
      <template v-slot:header-title>このチームのQRコード</template>
      <template v-slot:body>
        <div :class="$style.modal_content">
          <vue-qrcode v-if="displayQrGeneratorUrl" :value="displayQrGeneratorUrl" :options="qr_generator_options" tag="img"></vue-qrcode>
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          rounded="rounded"
          width="150px"
          height="50px"
          @click="clickQrCopy"
        >URLをコピーする</base-button>
      </template>
    </base-modal>
    <app-home-menu v-if="isShowMenu" @close="isShowMenu = false" />
    <app-tabbar @menu="toggleShowMenu()" :bottom-fixed="true" :is-show-menu="isShowMenu" />
  </div>
</template>

<script>
import AppFooter from '@/components/app/AppFooter/AppFooter'
import AppTabbar from '@/components/app/AppTabbar/AppTabbar'
import AppHomeMenu from '@/components/app/AppMenu/AppHomeMenu/AppHomeMenu'
import isMobile from "@/utils/mixins/isMobile";
import {mapMutations, mapGetters} from 'vuex'
import {fetchTeamInvite, fetchTeamUserInfo, editTeamUser} from '@/helper/common.js'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import VueQrcode from "@chenfengyuan/vue-qrcode";
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import TeamChatDetailHeader from "@/pages/team/components/TeamChatDetailHeader";
import AppScrollButton from '@/components/app/AppScrollButton/AppScrollButton'

export default {
  name: 'TeamNewLayout',
  mixins: [isMobile()],
  components: {
    AppFooter,
    BaseButton,
    BaseModal,
    VueQrcode,
    BaseIcon,
    BaseMessage,
    AppTabbar,
    AppHomeMenu,
    TeamChatDetailHeader,
    AppScrollButton
  },
  beforeMount() {
    this.$router.app.$on('page-updated', ({pageTitle}) => {
      this.title = pageTitle
    })
    this.$router.app.$on('show-spinner', (showFlg) => {
      this.showSpinner = showFlg
    })
    this.$router.app.$on('page-refresh', async (team_id ,callback) => {
      this.team_id = team_id
      await this.initFetch()
      callback()
    })
  },
  data() {
    return {
      childPageTitle: '',
      basePageTitle: '',
      showSetting: true,
      back: '',
      headerColor: 'white',
      bgColor: 'yellow',
      showTabbar: true,
      showFooter: true,
      showSpinner: false,
      showPcBackBtn: false,
      teamId: this.team_id,
      chatId: this.chat_id,
      previousPost: this.previousPost,
      previousType: this.previousType,
      nextPost: this.nextPost,
      nextType: this.nextType,
      team_info: {},
      scrollY: 0,
      invite: false,
      approval: false,
      favoriteFlg: false,
      auth_type: null,
      can_dissolve: false,
      show_dissolve: false,
      canTeamRuleEdit: false,
      initProcessing:false,
      processing: false,
      user_belongs: false, // 所属しているかフラグ
      showQrGrenerator: false,
      member_list_view_flg: false, // メンバー管理に遷移できるかフラグ
      displayQrGeneratorUrl: "",
      qr_generator_options: {
        errorCorrectionLevel: "M",
        maskPattern: 0,
        margin: 2,
        scale: 2,
        width: 300,
        color: {
          dark: "#000000FF",
          light: "#FFFFFFFF"
        }
      },
      teamErrorMsgList: [],
      isShowMenu: false,
      // ヘッダー用
      back_url: '/team/home/',
      title: '',
      blockUserFlg: false,
      editChatFlg: false,
      deleteAuthFlg: false,
      type: 'post',
      commentNoticeFlg: false,
      mentionNoticeFlg: false,
      pinFlg: false,
      showPinFlg: false,
      readFlg: false,
      readCount: '',
      headerMenuValue: '',
      resultObj: {},
      spam_flg: false,
      report_flg: false,
      user_team_status: '',
    }
  },
  computed: {
    ...mapGetters('team', ['getTeamUserEventFlg']),
    onTheEdge() {
      // post-createページの場合はtrueを返す
      return this.$route.name === 'TeamPostCreate'
    },
    className() {
      return {
        [this.$style.yellow]: this.bgColor === 'yellow',
        [this.$style.white]: this.bgColor === 'white'
      }
    },
    isFavorite() {
      return !!this.favoriteFlg == true
    },
    isEventDone() {
      return !!this.getTeamUserEventFlg == true
    },
    isValidUser() {
      if(this.auth_type && (this.auth_type == 'admin' || this.auth_type == 'admin_auth' || this.auth_type == 'user')){
        return true
      }
      return false
    },
    creatQrCodeUrl() {
        return `${process.env.VUE_APP_BASE_URL}/team/info/${this.team_id}`
    },
    getPageTitle() {
      if(this.childPageTitle) return this.childPageTitle
      if(this.basePageTitle) return this.basePageTitle
      return ''
    },
    isTablet() {
      const ua = navigator.userAgent.toLowerCase()
      // 前提条件：macはタッチスクリーン不採用
      return /ipad|macintosh|android/.test(ua) && 'ontouchend' in document
    },
    team_name () {
      return this.team_info?.name || ''
    },
    cover_image() {
      return this.team_info?.cover_image || ''
    },
    team_url () {
      return ''
    },
    isBeforeApproval: function() {
      return this.user_team_status === 'before_approval'
    },
  },
  methods: {
    toggleShowMenu() {
      this.isShowMenu = !this.isShowMenu
    },
    ...mapMutations('team', ['setTeamUserEventFlg']),
    async getTeamUserInfo () {
      // 組織ユーザー情報取得
      const {value} = await fetchTeamUserInfo({team_id: this.team_id})
      this.favoriteFlg = !!value?.favorite_flg
      this.setTeamUserEventFlg(value?.event_flg)
      this.auth_type = value?.auth_type

      if (
        this.auth_type == 'admin'
        && value?.black_list_flg == 0
        && (value?.status == 'approved' || value?.status == 'no_approval_required')
        ) {
          this.can_dissolve = true
        }

      // valueが返って来たら所属フラグを立てる
      if(value !== undefined){
        this.user_belongs = true
      }

      this.user_team_status = value?.status

    },
    focusComment () {
      this.$emit('focus-comment')
    },
    fetchTeamInfo () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/info', { params: { team_id: this.team_id }})
        .then((res) => {
            this.basePageTitle = res.data?.value?.team.name
            this.member_list_view_flg = res.data?.value?.team.member_list_view_flg == 1 ? true : false
            this.team_info = res.data?.value?.team
        })
    },
    //chat情報取得
    callbackChat(chat) {
      this.chat = chat
      //ブロックユーザーかどうか判断
      this.blockUserFlg = !!this.chat.block_user_flg
      //編集ボタンを表示するかどうか判断するためのフラグ
      this.editChatFlg = this.chat.block_user_flg ? false : !!this.chat.edit_chat_flg
      //削除を表示するかどうか判断するフラグ
      this.deleteAuthFlg = this.chat.block_user_flg ? false : !!this.chat.delete_auth_flg
      //投稿かミニ投票か判断するためのtype
      this.chatType = this.chat.type
      //ピン止めしてるか判断するためのフラグ
      this.pinFlg = !!this.chat.pin_flg
      //ピン止めの編集を表示するか判断するためのフラグ
      //コメントとメンション通知フラグ
      this.commentNoticeFlg = !!this.chat.comment_notice_flg
      this.mentionNoticeFlg = !!this.chat.mention_notice_flg
      //既読・未読フラグ
      this.readFlg = !!this.chat.read_flg
      //既読数
      this.readCount = this.chat.read_count ? String(this.chat.read_count) : '0'
      //お気に入りフラグ
      this.favoriteFlg = !!this.chat.user_favorite_flg
      //スパム、ユーザー通報フラグ
      this.spam_flg = !!this.chat.spam_flg
      this.report_flg = !!this.chat.is_user_reported
      if (this.chatType === 1 || this.chatType === 4) {
        this.type = 'post'
      } else {
        this.type = 'vote'
      }
      this.vote_choices = this.chat.vote_choices
      if (this.chat.block_user_flg) {
        this.showButtonSubmit = false
        this.showButtonResult = false
        this.showVoteChoices = false
        this.showPostView = false
      } else {
        this.showPostView = true
        this.showVoteChoices = true
        if (this.chat.is_send_button == 1) {
          this.showButtonSubmit = true
        }
        if (this.chat.is_vote_result_button == 1) {
          this.showButtonResult = true
        } else {
          this.showButtonResult = false
        }
      }
    },
    async fetchScreenAuthTeamPin() {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/screen_auth/team/pin', { team_id: this.team_id })
        .then((res) => {
          if (res.data.status == 'success') {
            this.showPinFlg = !!res?.data?.value?.pin_flg
          }
        })
    },
    callbackRead (read_info){
      this.readCount = read_info.read_count
      this.readFlg = read_info.target_flg
    },
    reflectData(resultObj) {
      this.resultObj = resultObj
      this.result_type = resultObj.type
      if(this.result_type == 'clip') {
        this.pinFlg = !!resultObj.data
      } else if (this.result_type == 'read') {
        this.readFlg = !!resultObj.data.read_flg
      } else if (this.result_type == 'favorite') {
        this.favoriteFlg = !!resultObj.data.user_favorite_flg
      }
      this.$emit('ref-data', resultObj)
    },
    async getTeamInvite () {
      // 未所属の場合はAPIを叩かせない
      if(! this.user_belongs){
        return
      }
      //組織への招待、組織への承認権限確認取得
      const {invite, approval} = await fetchTeamInvite({team_id: this.team_id})

      if(invite) this.invite = true // 0:組織への招待権限がない 1:組織への招待権限がある
      if(approval) this.approval = true // 0:組織への承認権限がない 1:組織への承認権限がある
    },
    fetchScreenAuthTeamUpdate: function () {
      this.canTeamRuleEdit = false

      // 未所属の場合はAPIを叩かせない
      if(! this.user_belongs){
        return
      }

      // 組織の編集権限を確認するAPI
      return this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/screen_auth/team/update', {team_id: this.team_id})
        .then((res) => {

          if (res.data.status == 'success') {
            this.canTeamRuleEdit = true
          }
        })
        .catch((err) => {
          if (err.response.status === 461) { // 組織の編集権限なし
            this.canTeamRuleEdit = false
          }
        })
    },
    async initFetch() {
      this.initProcessing = true
      try {
        if(this.team_id){
          await this.getTeamUserInfo()

          await Promise.all([
            this.fetchTeamInfo(),
            this.getTeamInvite(),
            this.fetchScreenAuthTeamUpdate(),
            await this.fetchScreenAuthTeamPin(),
          ])
        }
      }
      finally {
        this.initProcessing = false
      }
    },
    async favoriteClick() {
      if (this.processing) return

      this.processing = true
      // 組織ユーザー登録・更新 [お気に入りに追加する/削除する]
      const {status} = await editTeamUser({team_id: this.team_id, favoriteFlg: this.favoriteFlg ^ 1})
      if(status == 'success') {
        this.favoriteFlg = this.favoriteFlg ^ 1
      }
      this.processing = false

    },
    async inviteClick() {
      // 組織ユーザー更新 このチームに招待する
      this.showQrGrenerator = true
    },
    async withDrawClick() {
      if (this.processing) return
      var confirm_result = confirm('本当に脱退しますか。');
      if(!confirm_result) {
          return
      }

      this.teamErrorMsgList = []
      this.processing = true
      //  組織ユーザー登録・更新 [このチームを脱退する]
      const {status, errors} = await editTeamUser({team_id: this.team_id, status: 'withdrawal',auth_type:'not_user'})
      if(status == 'success') {
        // ホーム
        this.$router.push(`/home/home`)
      }else if(status == 'error'){
        this.teamErrorMsgList = errors
        setTimeout(() => this.teamErrorMsgList = [], 5000)
      }
      this.processing = false
    },
    async dissolveClick() {
      if (this.processing) return
      var confirm_result = confirm('本当にチームを解散しますか。');
      if(!confirm_result) {
          return
      }

      this.teamErrorMsgList = []
      this.processing = true
      //  チーム解散処理
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/dissolve', {team_id: this.team_id})
        .then((res) => {
          if (res.data.status == 'success') {
            this.$router.push(`/home/home`)
          }
        })
        .catch((err) => {
          this.teamErrorMsgList = err.response.data.value.errors
          setTimeout(() => this.teamErrorMsgList = [], 5000)
        })
      this.processing = false
    },
    ruleClick() {
      // チーム設定の編集
      this.$router.push(`/team/create/${this.team_id}`);
    },
    toTeamHome() {
      this.$router.push(`/team/home/${this.team_id}`)
    },
    memberManageClick() {
      // チームメンバー管理
      this.$router.push(`/team/${this.team_id}/manage-member`);
    },
    unionSalonSettingClick() {
      // UNION SALON 設定
      this.$router.push(`/union/setting/${this.team_id}`);
    },
    giftSendClick() {
      // メッセージ機能
      this.$router.push(`/union/gift/${this.team_id}`);
    },
    async eventClick() {
      if (this.processing) return

      this.processing = true
      //  組織ユーザー登録・更新 [通知をONにする/OFFにする]
      const {status} = await editTeamUser({ team_id: this.team_id, event_flg: this.isEventDone ^ 1})
      if(status == 'success') {
        this.setTeamUserEventFlg(this.isEventDone ^ 1)
      }
      this.processing = false
    },
    clickQrCopy() {
      if (this.processing) return

      this.processing = true
      const elem = document.createElement('input')
      elem.value = this.creatQrCodeUrl
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.processing = false
    },
    fetchPreviousAndNext(data) {
      this.previousPost = data.previousPost
      this.previousType = data.previousType
      this.nextPost = data.nextPost
      this.nextType = data.nextType
    },
  },
  watch: {
    showQrGrenerator(nv, ov) {
      if(!nv && ov){
        this.displayQrGeneratorUrl = ''
      }
      if(nv && !ov){
        this.displayQrGeneratorUrl = this.creatQrCodeUrl
      }
    }
  },
  created() {
    this.team_id = this.$route.params?.team_id
    this.chat_id = this.$route.params?.chat_id
    this.initFetch()
  },
}
</script>

<style lang="scss" module>
.team_content{
  // display: flex;
  max-width: 1280px;
  margin: 0 auto;
  flex: 1;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  padding-top: 220px;
  // padding-bottom: 70px;
  &.yellow {
    background: #FFFBE4;
  }
  &.white {
    background: #fff;
  }
  @include mobile() {
    min-height: calc(100vh - 70px - 50px);
    &.hide_tabbar {
      min-height: calc(100vh - 50px);
      padding-bottom: 0;
    }
  }
}

.team_content_body {
  // max-width: 1280px;
  margin: 0 auto;
  flex: 1;
  &_loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.header {
  height: 74px;
  display: flex;
  justify-content: right;
  align-items: center;
  &_link {
    cursor: pointer;
    margin-right: 36px;
    color: inherit;
    text-decoration: underline;
  }
  &_scroll {
    height: 74px;
    display: flex;
    // justify-content:;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100%;
  }
}
.footer {
  @include mobile() {
    display: none;
  }
}

.modal_content {
  margin: 0 20px !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile() {
    width: auto;
    margin: 20px;
  }
}

.msg_container{
  width: 980px !important;
  margin: 4px auto;
  background: #fff;
  @include mobile() {
    width: auto !important;
    margin: 4px;
    background: transparent;
  }
}
.msg_area {
  width: 100% !important;
  margin: 0 0 4px;
  &:last-of-type{
    margin-bottom: 0;
  }
}

.team {
  &_enter_active, &_leave_active {
    transition: opacity .5s;
  }
  &_enter, &_leave_to {
    opacity: 0;
  }
}

.back_area {
  width: 960px;
  margin: 0 auto;
  @include mobile() {
    display: none;
  }
}
</style>
