<template>
  <div :class="$style.home_notice">
    <div :class="$style.body" v-if="news_profile">
      <app-prev-button
          v-if="prevRoute"
          margin="1vw 1vw 3vw 1vw"
          @click="$router.push(prevRoute)"
      />
      <div :class="$style.notice">
        <div :class="[$style.notice_top, $style.inner]">
          <dt>
            <h3 :class="$style.title">{{news_profile.title}}</h3>
          </dt>
          <dd>
            <div
              :class="$style.notification_label_frame"
            >
            <span :class="$style.notification_label">お知らせ</span>
          </div>
            <time :class="$style.time">{{time(news_profile.start)}}</time>
          </dd>
        </div>
        <div :class="$style.img_container">
          <img
            :src="news_profile.path"
            :style="customStyle"
            :class="$style.notification_img"
          />
        </div>
        <p :class="[$style.notice_body, $style.inner]" v-html="autoLink(notice_body)">
        </p>
      </div>
      <form action="">
        <div v-for="(item, index) in attachment_list" :class="$style.file" :key='index'>
          <button :class="$style.file_download" @click.prevent="multi_download([item])">
            <img :class="$style.file_icon" src="@/assets/images/description_black_24dp.svg" alt="icon">
            {{getFileName(item.path)}}
          </button>
        </div>
        <p :class="$style.file_all" id="all_dl">
          <a :class="$style.file_all_download" @click="multi_download(attachment_list)" v-if="!processing && (attachment_list && attachment_list.length > 0)">ダウンロードする</a>
          <base-icon name="spinner" size="0.9vw" :class="$style.procecinng_download" spin v-if="processing" />
        </p>
        <div :class="$style.msg_container">
          <template v-if="hasError(downloadErrorMsgList)">
            <base-message type="error" v-for="(error, i) in downloadErrorMsgList" :key="i" :class="$style.msg_area">{{error.message ? error.message : error}}</base-message>
          </template>
          <base-message type="success" :class="$style.msg_area" v-if="downloadSuccessMsg">{{downloadSuccessMsg}}</base-message>
        </div>
      </form>
        <div :class="$style.notifications_container">
          <h2 :class="$style.list_header">直近のお知らせ</h2>
          <ul :class="$style.content_list">
            <li
              v-for="(post, index) in chatServiceNews"
              :class="[$style.content_item]"
              :key="index"
            >
              <app-notice-list :bodyColor="bodyColor" :post="post" />
            </li>
            <li
              v-if="!chatServiceNews || chatServiceNews.length === 0"
              :class="$style.no_post_item"
            >
              投稿はありません。
            </li>
          </ul>
          <button
          :class="$style.back_button"
          @click="$router.push(prevRoute)"
          >お知らせ一覧へ</button>
        </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import AppNoticeList from '@/components/app/AppNoticeList/AppNoticeList.vue'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'
import {hasError} from '@/helper/validator.js'
import {autoLink} from '@/utils/helper.js'

export default {
  name: 'HomeNotice',
  components: {
    BaseIcon,
    BaseMessage,
    AppNoticeList,
    AppPrevButton,
  },
  async mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '通知',
      headerColor: 'white',
      showSetting: false,
    })
    this.$router.app.$emit('show-spinner', true);
    await this.initFetch()
    await this.newsInfo();
    this.$router.app.$emit('show-spinner', false);
  },
  data() {
    return {
      current_tab: 'チーム',
      notice_body: '',
      img:'@/assets/images/cat-640.jpg',
      news_profile: null,
      attachment_list: [],
      downloadErrorMsgList: [],
      downloadSuccessMsg: null,
      processing: false,
      servicenews_list: [],
      prevRoute: '/home/home',
      bodyColor: '#f9f9f9'
    }
  },
  props: {
    news_id: {
      type: Number,
    },
  },
  computed: {
    chatServiceNews() {
      const list = this.servicenews_list
      return list.slice(0, 5)
    },
  },
  methods: {
    hasError,
    autoLink,
    newsInfo() {
      const params ={news_id: this.news_id};
      return this.$axios
                .get('/api/' + this.$constants.API_VERSION + '/servicenews/detail', {params})
                .then((response) => {
                  this.news_profile = response?.data?.value?.service_news
                  this.notice_body = response?.data?.value?.service_news?.contents
                  if(response.data?.value?.attachment_list){
                    if(response.data.value.attachment_list.length > 5){
                      this.attachment_list = [...response.data.value.attachment_list.slice(0, 5)]
                    }else{
                      this.attachment_list = response.data.value.attachment_list
                    }
                  }
                })
    },
    multi_download: async function (items) {
      if (this.processing) return

      this.downloadErrorMsgList = []
      this.downloadSuccessMsg = ''
      this.processing = true

      try {
        let files = items.map(attachment => {
          return {
            path: attachment.path
          }
        })
        // ファイルを複数ダウンロード出来るAPI call
        const result = await this.$axios.post('/api/' + this.$constants.API_VERSION + '/file/download/list', { file_list: files })
        if (result.data.status == 'success') {
            const file_list = result.data.value.file_list
            if(file_list && file_list.length > 0){
              file_list.forEach((file, index) => {
                if(file.error){
                  this.downloadErrorMsgList.push({message: `${index + 1}つめののダウンロードに失敗しました。`});
                }else{
                  this.downloadFile(file.image, file.file_name, "application/vnd.ms-excel")
                }
              })
              if(this.downloadErrorMsgList.length == 0) {
                this.downloadSuccessMsg = 'ダウンロードが完了しました。'
              }
            }else {
              this.downloadErrorMsgList.push({message: 'ダウンロード対象がありません。'});
            }
        }
        else{
          this.errorMsgList.push({message: 'ダウンロードに失敗しました。'});
        }
      } finally {
        this.processing = false
      }
    },
    downloadFile: function(data, fileName, type) {
      const decoded_utf8str = atob(data);
      let decoded = new Uint8Array(Array.prototype.map.call(decoded_utf8str, c => c.charCodeAt()));
      if(type == 'text/csv'){
        decoded = new TextDecoder().decode(decoded);
      }
      if (window.navigator.msSaveOrOpenBlob) {
        // Edge IE
        window.navigator.msSaveOrOpenBlob(decoded, fileName);
      } else {
        // Chrome firefox
        const url = URL.createObjectURL(new Blob([decoded], {type}));
        const elem = document.createElement('a');
        elem.href = url;
        elem.setAttribute('download', fileName);
        document.body.appendChild(elem);
        elem.click();

        URL.revokeObjectURL(url);
        elem.parentNode.removeChild(elem);
      }
    },
    getFileName(path) {
      if(!path) return ''
      return path.split('/').pop();
    },
    // direction はコメントアウトされていて使われていないが、requiredなので付与している
    fetchServiceNewsList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/servicenews/list', {
          params: {
            sort: 'created_at',
            direction: 'asc',
            limit: 5,
          },
        })
        .then((res) => {
          this.servicenews_list = res.data.value.servicenews_list
        })
    },
    async initFetch() {
      await Promise.all([
        this.fetchServiceNewsList(),
      ])
    },
    time: function (created_at) {
      let createdFormatStr = ''
      if(created_at){
        createdFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
      }
      return createdFormatStr
    },
  },
};
</script>

<style lang="scss" module>
.home_notice {
  display: flex;
  width: 100%;
  background-color: $backGround;
  justify-content: center;
  align-items: flex-start;
  &.loading {
    align-items: center;
  }
}

.body {
  width: 70%;
  padding: 0 2% 10% 2%;
  margin: 25px auto 0px auto;
  @include mobile_only(){
    width: 100%;
    margin: 0;
    padding: 1.5rem 2rem;
  }
}

.notification_label_frame {
  width: 60px;
  height: 15px;
  padding: 5px 10px;
  border-radius: 4px;
  border: solid 1px #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification_label {
  width: 56px;
  height: 14px;
  font-family: HiraginoSans-W6;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.notice {
  border: 1px solid $borderLiteGray;
  background-color: $keyWhite;
  min-width: 20rem;
  .notice_top {
    dt,dd {
      display: flex;
      align-items: center;
      margin: 0;
    }

    dt {
      .title {
        font-size: 1em;
        margin: 0 0 0 1rem;
      }
    }
    dd {
      padding: 0.5rem 1rem;
      .time {
        display: inline-block;
        font-size: 0.8rem;
        transform: scale(0.9);
        transform-origin: 0;
        width: 50vw;
        margin-left: 0.5rem;
      }
    }
  }

  .notice_body {
    white-space: pre-line;
    word-wrap: break-word;
    margin-left: 1.25rem;
    font-size: 1rem;
    line-height: 1.6rem;
  }

  .img_container {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  .notification_img {
    object-fit: contain;
    object-position: center center;
    width: 75%;
    max-height: 33vh;
    height: auto;
    display: block;
    margin: 0 auto;
  }

}
.file {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.25rem;

  .file_download {
    width: 100%;
    border: 1px solid #707070;
    background-color: $keyWhite;
    padding: 0.75rem 0.625rem;
    border-radius: 0.2rem;
    color: #000000;
    text-decoration: none;
    text-align: left;
    cursor: pointer;

    .file_icon {
      display: inline-block;
      padding-right: 0.375rem;
      width: 1.25rem;
      height: 1.5rem;
      vertical-align: middle;
    }
  }

  input {
    display: none;
  }
}
.file_all {
  text-align: right;
  margin: 0.3rem;
}

.file_all_download {
  cursor: pointer;
  color: $keyGreen;
  font-size: 0.75em;
}
.procecinng_download {
  margin: 0.25rem;
}

.inner {
  padding: 0.75rem 0.875rem;

  @media (min-width: 768px) {
    padding: 1.5rem 5rem;
  }
}

.team_tab_content, .notice_tab_content {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0 0;
  margin: 0 auto;
  overflow: hidden;

  @include mobile(){
    width: 100%;
    margin: 0;
    padding: 1.5rem 2rem;
  }
}

.team_item_list, .notice_item_list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  // 右端下端のマージンを打ち消す。ピッタリだと影が消えるので少し小さく取る
  margin-right: -2vw;
  margin-bottom: -0.5rem;
  @include mobile(){
    margin-right: -3vw;
    margin-bottom: -1rem;
  }

  .team_item_wrap, .notice_item_wrap {
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;

    @include mobile(){
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}

.msg_container {
  margin: 0.25rem auto;
  .msg_area {
    width: 100% !important;
    margin: 0 0 0.625rem;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}

.content_list {
  padding: 0;
  margin: 0.75rem 0 1.25rem 0;
  max-width: 80rem;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.content_item {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0;
  border-bottom: solid 0.0625rem $borderGray;
  &:first-child {
    border-top: solid 0.0625rem $borderGray;
  }
  & > *:not(:last-child) {
    margin-right: 0.625rem;
  }
}

.no_post_item {
    margin-top: 10px;
    font-size: 12px;
    margin-left: 7px;
}

.notifications_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list_header {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: #333;
  margin-right: auto;
}

.back_button {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid $keyGreen;
  color: $keyGreen;
  background-color: #ffffff;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

</style>
