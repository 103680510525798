<template>
  <article :class="$style.article_wrapper">
    <div :class="$style.wrapper">
      <div :class="$style.content_header" ref="scrollTarget">
        <div :class="$style.content_header_pc">
          <div :class="$style.avatar_wrapper">
            <base-avatar
              size="30px"
              :border-color="color"
              :bordered="!!color"
              :class="$style.avatar"
              :path="userImagePath"
            />
          </div>
          <div :class="$style.info_wrapper">
            <div :class="$style.name">{{ name }}</div>
            <div :class="$style.content_data" v-show="showComment">
              <span :class="$style.text">{{ dateString }}</span>
              <div :class="$style.likes">
                <span :class="$style.text">
                  <span :class="$style.count">{{ goodCount }}</span>
                  {{ goodCount > 1 ? 'likes' : 'like' }}
                </span>
              </div>
              <div :class="$style.edit">
                <div>
                  <span :class="$style.text" @click="editReply" v-if="editableFlg">
                    編集
                  </span>
                  <span :class="$style.text" v-if="editableFlg"> / </span>
                  <span
                    :class="$style.text"
                    @click="showCommentModal = true"
                    v-if="deletableFlg"
                  >
                    削除
                  </span>
                </div>
              </div>
              <a
                v-if="
                  (fileType(pathList) == 'image' || fileType(pathList) == 'file') &&
                  showComment
                "
                :class="$style.picture"
                href="#"
                @click.prevent.stop="fileDownload(pathList[0].path)"
              >
                ダウンロードする
              </a>
              <div :class="$style.menu" v-show="showEllipsis">
                <base-overlay-menu-list v-if="!isBeforeApproval">
                  <base-overlay-menu-list-item
                    to=""
                    @click.native="handleReport"
                    v-if="showReport"
                  >
                    {{
                      report_flg
                        ? '※このユーザは通報済みです'
                        : 'このユーザを通報する'
                    }}
                  </base-overlay-menu-list-item>
                  <base-overlay-menu-list-item
                    @click.native="handleBlock"
                    to=""
                    v-if="showBlock"
                    >{{
                      blockUser
                        ? 'このユーザのブロックを解除する'
                        : 'ユーザをブロックする'
                    }}</base-overlay-menu-list-item
                  >
                  <base-overlay-menu-list-item
                  to=""
                  @click.native="handleSpam"
                  v-if="showSpam"
                >
                  {{
                    spam_flg
                      ? '※このコメントは通報済みです'
                      : 'このコメントを通報する'
                  }}
                </base-overlay-menu-list-item>
                </base-overlay-menu-list>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.content_wrapper" :style="customStyle">
        <div :class="$style.content_header_sp">
          <base-avatar
              size="20px"
              :border-color="color"
              :bordered="!!color"
              :class="$style.avatar"
              :path="userImagePath"
          />
          <div :class="$style.content_data_wrapper">
            <span :class="$style.name">{{ name }}</span>
            <div :class="$style.content_data" v-show="showComment">
              <span :class="$style.text">{{ dateString }}</span>
              <div :class="$style.likes">
                <span :class="$style.text">
                  <span :class="$style.count">{{ goodCount }}</span>
                  {{ goodCount > 1 ? 'likes' : 'like' }}
                </span>
              </div>
              <a :class="$style.edit">
                <div>
                  <span :class="$style.text" @click="editReply" v-if="editableFlg">
                    編集
                  </span>
                  <span :class="$style.text" v-if="editableFlg"> / </span>
                  <span
                    :class="$style.text"
                    @click="showCommentModal = true"
                    v-if="deletableFlg"
                  >
                    削除
                  </span>
                </div>
              </a>
              <a
                v-if="
                  (fileType(pathList) == 'image' || fileType(pathList) == 'file') &&
                  showComment
                "
                :class="$style.picture"
                href="#"
                @click.prevent.stop="fileDownload(pathList[0].path)"
              >
                ダウンロードする
              </a>
              <div :class="$style.menu" v-show="showEllipsis">
                <base-overlay-menu-list>
                  <base-overlay-menu-list-item
                    to=""
                    @click.native="handleReport"
                    v-if="showReport"
                  >
                    {{
                      report_flg
                        ? '※このユーザは通報済みです'
                        : 'このユーザを通報する'
                    }}
                  </base-overlay-menu-list-item>
                  <base-overlay-menu-list-item
                    @click.native="handleBlock"
                    to=""
                    v-if="showBlock"
                    >{{
                      blockUser
                        ? 'このユーザのブロックを解除する'
                        : 'ユーザをブロックする'
                    }}</base-overlay-menu-list-item
                  >
                  <base-overlay-menu-list-item
                  to=""
                  @click.native="handleSpam"
                  v-if="showSpam"
                >
                  {{
                    spam_flg
                      ? '※このコメントは通報済みです'
                      : 'このコメントを通報する'
                  }}
                </base-overlay-menu-list-item>
                </base-overlay-menu-list>
              </div>
            </div>
          </div>
        </div>
        <p :class="$style.content_body" v-show="showComment">
          <span v-if="type == 1" :class="$style.body" v-html="$sanitize(body)">
          </span>
          <span v-else-if="type == 2 || type == 4">
            <span v-if="pathList" :class="$style.picture">
              <span v-if="fileType(pathList) == 'image'">
                <img :src="fileUrl(pathList)" @click="imageClick" alt="" />
              </span>
              <span v-else-if="fileType(pathList) == 'file'" :class="$style.file">
                <button :class="$style.file_download">
                  <img
                    :class="$style.file_icon"
                    src="@/assets/images/file_download.svg"
                    alt="icon"
                  />
                  {{ filePath(pathList) }}
                </button>
              </span>
              <span v-else-if="fileType(pathList) == 'audio_file'">
                <audio :src="fileUrl(pathList)" controls="" style="width: 260px">
                  非対応
                </audio>
              </span>
            </span>
          </span>
          <span v-else-if="type == 3" :class="$style.stamp">
            <img :src="stampUrl" alt="" />
          </span>
        </p>
        <a
          :class="$style.reply_button"
          @click="openReplyModal()"
        >
          返信
        </a>
        <div v-if="switchReply" :style="link_styles">
          ブロックしたユーザーからの投稿です<br />投稿を表示しますか？
          <a href="#" @click.prevent.stop="switchReplyView()">Yes</a>
        </div>
      </div>
      <div :class="$style.icon_wrapper" @click="like" v-if="!isBeforeApproval">
        <base-icon
          v-if="showFavorite"
          size="22px"
          :type="goodFlg ? 'fas' : 'far'"
          name="heart"
          :color="goodFlg ? 'pink' : 'gray'"
          :class="[$style.icon, $style.like]"
        />
      </div>
      <base-modal
        v-if="showCommentModal"
        @close="showCommentModal = false"
        height="130px"
        type="round"
      >
        <template v-slot:header-title>コメントを削除しますか？</template>
        <template v-slot:body>
          <div :class="$style.stamp_tab_wrap">
            <comment-tab @commentDelete="deleteReply" />
          </div>
        </template>
      </base-modal>
      <base-modal
        v-if="showImage"
        type="round"
        imageEnlargementFlg
        @close="showImage = false"
      >
        <template v-slot:body>
          <div :class="$style.modal_content">
            <img :src="fileUrl(pathList)" :class="$style.modal_image" alt="" />
          </div>
        </template>
      </base-modal>
      <app-reply-modal
        v-if="showReplyModal"
        @send="handleSendReply"
        @close="handleCloseReplyModal"
        @download="fileDownload"
        :type="type"
        :body="replyBody"
        :userImagePath="userImagePath"
        :pathList="replyPathList"
        :stampUrl="replyStampUrl"
        :name="name"
        :mentionId="userId"
        :dateString="dateString"
        :commentId="commentId"
        :replyId="replyId"
        :chatId="chatId"
        :to_personal_options="to_personal_options_reply"
        :to_personal_loading="to_personal_loading"
        :savedReplyId="savedReplyId"
        :savedText="savedReplyText"
      />
      <app-reply-edit-modal
        v-if="showReplyEditModal"
        @send="handleSendReplyEdit"
        @close="handleCloseReplyEditModal"
        @download="fileDownload"
        @changeText="isEditing = true"
        :type="targetType"
        :body="targetText"
        :userImagePath="userImagePath"
        :pathList="targetFile"
        :stampUrl="targetStamp"
        :name="name"
        :userId="userId"
        :dateString="dateString"
        :commentId="commentId"
        :replyId="replyId"
        :chatId="chatId"
        :to_personal_options="to_personal_options_reply"
        :to_personal_loading="to_personal_loading"
        :savedReplyId="savedReplyEditId"
        :savedText="savedReplyEditText"
        :mentionsForEdit="mentionsForEdit"
      />
    </div>
  </article>
</template>

<script>
import BaseAvatar from '../../base/BaseAvatar/BaseAvatar.vue'
import BaseIcon from '../../base/BaseIcon/BaseIcon.vue'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import CommentTab from '../../base/BaseInputComment/CommentTab.vue'
import BaseOverlayMenuList from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuList'
import BaseOverlayMenuListItem from '@/components/base/BaseOverlayMenuList/BaseOverlayMenuListItem'
import AppReplyModal from './AppReplyModal.vue'
import AppReplyEditModal from './AppReplyEditModal.vue'
import {fetchCommentReplyList} from '@/helper/common.js'

export default {
  components: {
    BaseOverlayMenuListItem,
    BaseOverlayMenuList,
    BaseAvatar,
    BaseIcon,
    BaseModal,
    CommentTab,
    AppReplyModal,
    AppReplyEditModal,
  },
  name: 'AppCommentReplyListItem',
  props: {
    replyId : {
      type: Number,
      default: null,
    },
    /** ユーザー画像パス。BaseAvatarに準じる */
    userImagePath: {
      type: String,
    },
    /** アバター色 */
    color: {
      type: String,
    },
    /** コメントID */
    commentId: {
      type: Number,
    },
    chatId: {
      type: Number,
    },
    userId: {
      type: String,
    },
    /** 投稿者名前 */
    name: {
      type: String,
    },
    /** タイプ */
    type: {
      type: Number,
    },
    /** 本文 */
    body: {
      type: String,
      default: undefined,
    },
    /** 画像 */
    image: {
      type: String,
      default: '',
    },
    /** 添付ファイル  */
    pathList: {
      type: Array,
      default: null,
    },
    /** スタンプURL */
    stampUrl: {
      type: String,
      default: null,
    },
    dateString: {
      type: String,
      default: '',
    },
    /** like数 */
    goodCount: {
      type: Number,
      default: 0,
    },
    /** likeかどうか */
    goodFlg: {
      type: Boolean,
      default: false,
    },
    /** 編集可能かどうか */
    editableFlg: {
      type: Boolean,
      default: false,
    },
    /** 削除可能かどうか */
    deletableFlg: {
      type: Boolean,
      default: false,
    },
    replyCount: {
      type: Number,
      default: 0,
    },
    /** 画像拡大機能かどうか */
    imageEnlargementFlg: {
      type: Boolean,
      default: false,
    },
    blockUser: {
      type: Boolean,
      default: false,
    },
    showBlock: {
      type: Boolean,
      default: true,
    },
    showEllipsis: {
      type: Boolean,
      default: true,
    },
    showFavorite: {
      type: Boolean,
      default: true,
    },
    showComment: {
      type: Boolean,
      default: true,
    },
    switchReply: {
      type: Boolean,
      default: false,
    },
    bodyColor: {
      type: String,
      default: '#FFFFFF',
    },
    showReport: {
      type: Boolean,
      default: true,
    },
    report_flg: {
      type: Boolean,
      default: false,
    },
    showSpam: {
      type: Boolean,
      default: true,
    },
    spam_flg: {
      type: Boolean,
      default: false,
    },
    isBeforeApproval: {
      type: Boolean,
      default: false
    },
    to_personal_options: {
      type: Array,
    },
    to_personal_loading: {
      type: Boolean,
    },
    /** コメントの本文 */
    commentBody: {
      type: String,
      default: undefined,
    },
    /** コメントの添付ファイル  */
    commentPathList: {
      type: Array,
      default: null,
    },
    /** コメントのスタンプURL */
    commentStampUrl: {
      type: String,
      default: null,
    },
    targetText: {
      type: String,
      default: null,
    },
    targetStamp: {
      type: String,
      default: null,
    },
    targetFile: {
      type: Array,
      default: null,
    },
    targetType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      processing: false,
      showCommentModal: false,
      showImage: false,
      showReplyModal: false,
      showReplyEditModal: false,
      savedReplyText: '',
      savedReplyId: null,
      savedReplyEditText: '',
      savedReplyEditId: null,
      replyBody: this.body,
      replyPathList: this.pathList,
      replyStampUrl: this.stampUrl,
      replies: [],
      mentionsForEdit: [],
      newReplyCount: null,
      isOpen: false,
      isEditing: false,
      link_styles: {
        color: 'gray',
        fontSize: '9pt',
        textAlign: 'center',
        width: '287px',
      },
      to_personal_options_reply: [],
    }
  },
  methods: {
    handleSendReply() {
      this.$emit('send')
      this.handleCloseReplyModal('', null)
    },
    handleSendReplyEdit() {
      this.isEditing = false
      this.$emit('edit')
      this.handleCloseReplyEditModal('', null)
    },
    handleCloseReplyModal(replyText, replyCommentId, replyreplyId) {
      this.showReplyModal = false
      this.savedReplyText = replyText
      this.savedReplyId = replyreplyId
    },
    handleCloseReplyEditModal(replyEditText, replyEditCommentId) {
      this.showReplyEditModal = false
      this.savedReplyEditText = replyEditText
      this.savedReplyEditId = replyEditCommentId
    },
    handleBlock() {
      this.$emit('blockUser', this.replyId)
    },
    switchReplyView() {
      this.$emit('switchReplyView', this.replyId)
    },
    handleReport() {
      // ユーザ通報イベント
      this.$emit('report', this.item)
    },
    handleSpam() {
      //スパム通報イベント
      this.$emit('spam', this.item)
    },
    like() {
      this.$emit('like', this.replyId, this.goodFlg)
    },
    async editReply() {
      if (this.processing) return
      if (this.showReplyEditModal) return
      this.processing = true
      // メンション相手抽出
      // const regexForMention = /juid="(.*?)"/g
      // const mentions = Array.from(this.body.matchAll(regexForMention))
      // const juids = mentions.map(match => match[1])
      // await this.$axios
      //   .get('/api/' + this.$constants.API_VERSION + '/team/chat/comment/reply/mention', {
      //     params: { juids: juids }
      //   })
      //   .then((res) => {
      //     if (res.data.status == 'success') {
      //       this.mentionsForEdit = res.data.value.mention_user_list
      //     }
      //   }).catch((err) => {
      //     if (err.response.status != '200') {
      //       alert(err.response.data.value.errors[0].message)
      //     }
      //     this.processing = false
      //   })
      // テキスト抽出
      const result = this.body.replace(/<span[^>]*>(<mention[^>]*>[^<]*<\/mention>)<\/span>/g, '$1');

      // リプライモーダルに渡す
      if (!this.isEditing) {
        this.savedReplyEditText = result
      }
      this.savedReplyEditId = this.replyId

      this.processing = false
      this.showReplyEditModal = true
    },
    deleteReply() {
      this.$emit('deleteReply', this.replyId)
      this.showCommentModal = false
    },
    openReplyList() {
      if (!this.isOpen) {
        this.fetchReplyList()
      }
      this.isOpen = !this.isOpen
    },
    fetchReplyList() {
      const params = {
        // offset = Number.isNaN(offset) ? 0 : offset
        comment_id: this.commentId,
        sort: 'created_at',
        // direction: this.direction,
        // limit: this.perPage,
        // offset: offset,
      }
      const res = fetchCommentReplyList(params)
      if (res.status == 'success') {
        this.replies = res.value.replies
        this.newReplyCount = res.value.comment_reply_count
      } else {
        this.replies = null
        this.newReplyCount = 0
      }
    },
    fileDownload: async function (path) {
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/file/download', {
          path: path,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            let base64 = res.data.value.image
            let bin = atob(base64.replace(/^.*,/, ''))
            let buffer = new Uint8Array(bin.length)
            for (var i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i)
            }

            let blob = new Blob([buffer.buffer])
            let a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = res.data.value.file_name
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        })
    },
    fetchReplyMentionList: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/mention/list', {
          params: { chat_id: this.$route.params.chat_id , comment_id: this.commentId }
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.to_personal_options_reply = []
            const userList = res.data.value?.mention_user_list
            if (userList) {
              userList.forEach((user) => {
                this.to_personal_options_reply.push({
                  id: user.user_id,
                  juid: user.juid,
                  nickname: user.nickname,
                  label: user.nickname
                    ? user.nickname
                    : user.name
                    ? user.name
                    : user.juid,
                  choicesLabel: user.nickname_juid,
                  profile_image_path: user.profile_image_path
                });
              });
            }
            // this.to_personal_loading = false;
            console.log(res.data.value?.mention_user_list)
            this.users = res.data.value.mention_user_list.map(user => {
              return {
                user_id: user.user_id,
                value: user.nickname,
              }
            })
            this.users_count = this.users.length;
          } else {
            this.users = []
          }
        })
    },
    async openReplyModal() {
      await this.fetchReplyMentionList()
      this.showReplyModal = true
    },
    imageClick() {
      this.showImage = true
    },
    fileType: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].file_type
    },
    filePath: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].path.substring(path_list[0].path.lastIndexOf('/') + 1)
    },
    fileUrl: function (path_list) {
      if (!path_list || path_list.length == 0) return ''

      return path_list[0].file_url
    },
  },
  computed: {
    customStyle() {
      return {
        'background-color': `${this.bodyColor}`,
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;

  &:not(:last-child) {
    //margin-bottom: 20px;
  }

  & > *:not(:last-child) {
    // margin-right: 10px;
  }

  .avatar_wrapper {
    .avatar {
    }
  }

  .content_wrapper {
    flex: 1;
    padding: 5px 15px 5px 0px;
    margin-left: 5px;
    .content_body {
      font-size: 15px;
      line-height: 1.7;
      color: $keyBlack;
      margin: 0;
      margin-bottom: 2px;

      & > *:not(:last-child) {
        margin-right: 14px;
      }
      .name {
        font-weight: bolder;
        vertical-align: top;
      }
      .body {
        display: block;
        // white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-word;
      }
      .body img {
        max-width: 100%;
        height: auto;
      }
      .image {
      }
      .block_link {
        display: block;
        align-items: center;
        justify-content: center;
        font-size: 10px;
      }
    }
    .content_image {
      margin: 0 auto;
      max-width: 200px;
      max-height: 300px;
      img {
        max-width: 200px;
        max-height: 300px;
      }
    }
    .content_data {
      line-height: 10px;
      color: #b7b7b7;

      & > * {
        display: inline-block;
        min-width: 20px;
        height: 10px;
      }
      & > *:not(:last-child) {
        // margin-right: 10px;
      }

      .text {
        display: inline-block;
        font-size: 10px;
      }

      .likes {
        .count {
          margin-right: 2px;
        }
      }
      .edit {
        user-select: none;
        cursor: pointer;
      }
      .picture {
        color: $KeyDarkGreen;
        font-size: 10px;
        padding: 3px 0 0 0;
        width: 80px;
        float: right;
      }
      .menu {
        width: 18px;
        height: 18px;
        display: inline-block;
        align-items: center;
        justify-content: center;
        margin: 5px;
      }
    }
    .reply_button {
      font-size: 18px;
      color: #4AC0C0;
      cursor: pointer;
      text-decoration: underline;
      @include mobile {
        font-size: 14px;
      }
    }
  }

  .icon_wrapper {
    .icon {
      user-select: none;
      cursor: pointer;
    }

    .like {
    }
  }

  .picture {
    padding: 0;
    vertical-align: top;
    display:block;

    img {
      max-width: 200px;
      max-height: 300px;
    }

    .file {
      width: 100%;
      justify-content: center;

      .file_download {
        width: 160px;
        border: 1px solid #707070;
        background-color: $keyWhite;
        padding: 4px 4px;
        color: #000000;
        text-decoration: none;
        text-align: left;

        .file_icon {
          display: inline-block;
          padding-right: 6px;
          width: 16px;
          height: 20px;
          vertical-align: middle;
        }
      }

      input {
        display: none;
      }
    }
  }

  .stamp {
    padding: 0;
    vertical-align: top;
    display:block;
  }
}

.reply_toggle {
  margin-left: 85px;
  a {
    color: #4AC0C0;
  }
  .open_arrow {
    color: #4AC0C0;
    display: inline-block;
    transform: scale(0.5);
    transform-origin: center;
  }
  .close_arrow {
    color: #4AC0C0;
    display: inline-block;
    transform: scale(0.5);
    transform-origin: center;
  }
}

.modal_content {
  margin: 16px auto;
  width: 80%;
  @include mobile() {
    width: auto;
    margin: 16px;
  }
  &_description {
    font-size: 11px;
  }
  &_name {
    font-size: 16px;
    margin-left: 16px;
  }
  h2 {
    font-size: 14px;
  }
  section:first-of-type {
    margin-bottom: 56px;
  }
}
.modal_image {
  width: 100%;
}


// phase2.3
.content_header{
  &_pc{
    display: flex;
    width: 200px;
    gap: 0px;

    .info_wrapper{
      font-size: 13px;
      .name{
        font-weight: bold;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
    .content_data{
      font-size: 12px;
      color: rgb(96,96,96);
      display: flex;
      flex-wrap: wrap;
      .likes{
        .text{
          margin: 0 10px;
        }
      }
      .menu{
        margin: 0 0 0 10px;
      }

    }
  }
  &_sp{
    display: none;
  }
}
//投稿/ミニ投票詳細画面で「編集/削除」のマウスオーバーを指アイコンに修正
.edit {
        user-select: none;
        cursor: pointer;
      }

.wrapper {
  .content_wrapper {
    .content_body {
      .name {
        display: none;
      }
    }
    .content_data{
      display: none;
    }
    p {
      margin-block: 0 0;
    }
  }

  .picture{
    color: #4AC0C0;
  }
}

@include mobile_only() {
  .content_header{
    &_pc{
      display: none;
    }
    &_sp{
      display: flex;
      align-items: center;
      .avatar {
        margin-right: 5px;
      }
    }
  }
  .wrapper {
    .content_data_wrapper {
      display: flex;
      flex-direction: column;
    }
    .content_wrapper {
      .name {
        font-size: 12px;
      }
      .content_data {
        display: block;
        margin-top: -5px;
        .picture {
          margin-top: 10px;
        }
        .menu,
        .edit,
        .likes {
          margin-left: 10px;
        }
      }
      .content_body {
        font-size: 12px;
        .name {
          display: block;
        }
        .body {
          margin: 4px 0;
        }
      }
      .reply_button {
        font-size: 12px;
      }
    }
  }
}
</style>
