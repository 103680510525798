var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.content_inner},[(_vm.post.sso_url)?_c('section',{class:_vm.$style.wrapper,style:(_vm.customStyle),on:{"click":function($event){return _vm.goTo(_vm.post, _vm.post.type)}}},[_c('div',{class:_vm.$style.header_wrapper},[(_vm.post.service_id)?_c('div',{class:_vm.$style.sso_logo_wrapper},[_c('img',{attrs:{"src":_vm.sso_logo_imgpath(_vm.post.service_id, _vm.post.service_code)}})]):_vm._e(),_c('div',{class:_vm.$style.time},[_c('time',{class:_vm.$style.time_text,attrs:{"datetime":_vm.timeAttr}},[_vm._v(_vm._s(_vm.post.created_at))])]),(_vm.post.team_name)?_c('p',{class:_vm.$style.team_name},[_vm._v(" "+_vm._s(_vm.post.team_name)+" ")]):_vm._e(),_c('div',{class:_vm.$style.icons_area},[_c('div',{class:_vm.$style.icons},[_c('span',{class:[_vm.$style.icons_wrapper]},[_c('span',{class:[
                _vm.$style.icons_inner,
                _vm.$style.read,
                ( _obj = {}, _obj[_vm.$style.on] = _vm.post.read_flg, _obj ) ]},[_c('base-icon',{attrs:{"type":"fas","name":"eye","size":"10px","color":_vm.post.read_flg ? 'green' : 'gray'}}),_vm._v(" "+_vm._s(_vm.post.read_count)+" ")],1),_c('span',{class:[
                _vm.$style.icons_inner,
                _vm.$style.commented,
                ( _obj$1 = {}, _obj$1[_vm.$style.on] = _vm.post.comment_count > 0, _obj$1 ) ]},[_c('span',{class:_vm.$style.icon},[_c('base-icon',{attrs:{"size":"10px","name":"comment-dots"}})],1),_vm._v(" "+_vm._s(_vm.post.comment_count)+" ")]),_c('span',{class:[
                _vm.$style.icons_inner,
                _vm.$style.like,
                ( _obj$2 = {}, _obj$2[_vm.$style.on] = _vm.post.good_flg, _obj$2 ) ],on:{"click":function($event){$event.stopPropagation();return _vm.clickGood(_vm.post, _vm.post.chat_id)}}},[_c('base-icon',{attrs:{"name":"heart","size":"10px","type":"fas","color":_vm.post.good_flg ? 'pink' : 'gray'}}),_vm._v(" "+_vm._s(_vm.post.goods_count)+" ")],1)])])])]),_c('p',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.post.title))])]):(_vm.post.news_id)?_c('section',{class:_vm.$style.wrapper,style:(_vm.customStyle),on:{"click":function($event){return _vm.goToNews(_vm.post.news_id)}}},[_c('div',{class:_vm.$style.header_wrapper},[(_vm.post.service_id)?_c('div',{class:_vm.$style.sso_logo_wrapper},[_c('img',{attrs:{"src":_vm.sso_logo_imgpath(_vm.post.service_id, _vm.post.service_code)}})]):_vm._e(),_c('div',{class:_vm.$style.news_label},[_vm._v("お知らせ")]),_c('div',{class:_vm.$style.time},[_c('time',{class:_vm.$style.time_text,attrs:{"datetime":_vm.timeAttr(_vm.post)}},[_vm._v(_vm._s(_vm.post.start))])]),(_vm.post.team_name)?_c('p',{class:_vm.$style.team_name},[_vm._v(" "+_vm._s(_vm.post.team_name)+" ")]):_vm._e()]),_c('p',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.post.title))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }