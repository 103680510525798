<template>
  <div :class="customClass">
    <div :class="$style.form_textarea_area">
      <div v-if="$slots.prepend" :class="$style.form_textarea_prepend">
        <slot name="prepend" />
      </div>
      <editor
        :class="[$style.form_textarea, errorClass]"
        :init="init"
        :tinymce-script-src="'/tinymce/tinymce.min.js'"
        v-bind="$attrs"
        v-model="boundValue"
        ref="rich_editor"
        @onChange="changed"
        @onDrop="changed"
        @onPaste="changed"
        @onKeyUp="keyUp"
      ></editor>
      <!-- output-format="text" -->

      <div
        v-if="$slots.append"
        :class="[$style.form_textarea_append, errorClass]"
      >
        <slot name="append" />
      </div>
    </div>
    <div class="toolbar"></div>

    <p v-if="!!errorText" :class="$style.error_txt">{{ errorText }}</p>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'BaseRichTextEditor',
  // inheritAttrs: false,
  components: {
    Editor,
  },
  model: {
    prop: 'modelValue',
    event: 'update',
  },
  props: {
    modelValue: {},
    rows: {
      type: Number,
      default: 1,
    },
    fixedHeight: {
      type: String,
      default: null,
    },
    /**
     * エラー時の文言を指定するとエラー表示になります
     * String: そのままエラー文として表示します。空文字列は無視されます
     * Boolean: trueならエラー文なしで枠のみがエラー表示になります。
     */
    error: {
      type: [String, Boolean],
      default: '',
    },
  },
  mounted() {
    this.resize()
  },
  data() {
    return {
      value: '',
      height: '42px',
      offsetHeight: 14,
      lineHeight: 24,
      borderHeight: 2,
      marginTop: 8,
      marginBottom: 6,
      init: {
        license_key: 'gpl',
        height: 328,
        menubar: false,
        inline: true,
        verify_html: false, // 属性を削除しない
        fixed_toolbar_container: '.toolbar',
        plugins: [
          'autoresize', 'autolink', 'image'
          //   'print preview fullpage importcss searchreplace autolink \
          //   autosave save directionality visualblocks visualchars fullscreen image link media template codesample \
          //   table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount \
          //   imagetools textpattern noneditable help charmap quickbars  emoticons'
        ],
        toolbar: [
          'fontsize | forecolor | bold | underline | image ',
        ],
        relative_urls: false,  // 相対URLを使用しない
        image_description: false, // 画像説明入力フィールドを無効
        file_picker_types: 'image',
        images_upload_handler: this.images_upload_handler,
        language: 'ja',
        statusbar: false,
        newline_behavior: 'block',
        outputFormat: 'text',
      },
      external_plugins: {
        custom_plugin: '/tinymce/plugins/custom_plugin/plugin.min.js',
      },
      skin_url: '/tinymce/skins/ui/oxide',
      content_css: '/tinymce/skins/content/default/content.min.css',
      outputExample:
        '<p>あ<u>いう</u>え<b>お</b></p><p>か<font color="#ff0000">きくけ</font>こ</p><p><font face="Times New Roman">さしす<font size="5">せそ</font></font></p>',
    }
  },
  computed: {
    customClass() {
      return [
        this.$style.wrapper,
        {
          [this.$style.has_prepend]: !!this.$slots.prepend,
          [this.$style.has_append]: !!this.$slots.append,
          [this.$style.focus]: !!this.focus,
          [this.$style.error]: !!this.error,
        },
      ]
    },
    errorClass() {
      return { [this.$style.error]: !!this.error }
    },
    boundValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update', value)
      },
    },
    errorText() {
      return typeof this.error === 'string' ? this.error : ''
    },
    editorHeight() {
      return `${
        this.lineHeight * this.rows +
        this.marginTop +
        this.marginBottom +
        this.borderHeight * 2
      }px`
    },
  },
  methods: {
    resize() {
      if (this.fixedHeight) {
        this.height = this.fixedHeight
        return
      }

      this.height = this.editorHeight
    },
    changed(event, editor) {
      // 値だけ取得（文字数カウント用）
      const user_input = editor.getContent({ format: 'text' })
      this.$emit('text_changed', user_input)

      // 改行を2文字扱いから1文字扱いに変更
      const target = user_input.replace(/\n\n/g, '_')

      let length = target.length
      // 末尾が改行 + 入力なしの場合「\n\nbsp;」と出力され、1文字多くカウントされているため減らす
      if (user_input.length > 1 && user_input.slice(-3).match(/\n\n\s/)) {
        --length
      }

      // this.$emit('text_changed', user_input.replase(/\r\n?/g, "\n"))
      this.$emit('text_length', length)
      // リサイズする
      // editor.container.style.height = '400px'
    },
    keyUp(event, editor) {
      if (event.keyCode == 13 && event.shiftKey == true) {
        // Shift+Enterで<br>が付与されるので、<p>タグに置き換える
        editor.execCommand('Undo')
        editor.execCommand('mceInsertNewLine')
      }
      if (event.key == '@') {
        editor.execCommand('mceFocus')
      }
      this.changed(event, editor)
    },
    /**  */
    mention(value) {
      const mentioned_user = value.trim()
      console.log(mentioned_user)

      // const re = new RegExp(`@${mentioned_user}\S`)
      // const match = this.comment_text.match(re)
      // if (match) {
      //   // マッチした文字列から空白と@を削除
      //   const matchedNickName = match.map((e) => e.replace(/\s+/g, '').slice(1))
      //   // 重複削除
      //   const deduplicated = [...new Set(matchedNickName)]
      // }
      // this.changed(event,editor)
    },
    images_upload_handler(blobInfo) {
      return new Promise((resolve,reject) => {
        this.$axios
          .post("/api/" + this.$constants.API_VERSION + "/team/chat/upload/image", { path: blobInfo.base64(), file_name: blobInfo.filename() })
          .then(async (response) => {
            resolve(response.data.value.path);
          })
          .catch((err) => {
            let errorMessage = '画像のアップロードに失敗しました。'
            const errorData = err.response.data

            if (errorData.value && errorData.value.errors) {
              errorMessage = errorData.value.errors
                .map((e) => e.message)
                .join(', ')
            }

            /// reject実行時にTinyMCEデフォルトのエラーポップアップが表示される
            reject(new Error(errorMessage))
          })
      })
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  width: 100%;
  .error {
    border-color: $keyRed;
  }
}

.form_textarea_area {
  display: flex;
  align-items: stretch;
  overflow: auto;
  resize: vertical;
  height: 258px;
}

.form_textarea {
  display: block;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-left: 10px;
  flex: 1;
  outline: none;
  background: #fff;
  transition: all 0.1s;
  overflow-y: auto;
}

.form_textarea:disabled,
.form_textarea:read-only {
  background: #e7e7e7;
}

.form_textarea,
.form_textarea_prepend,
.form_textarea_append {
  font-size: 16px;
  line-height: 1.5;
  color: #262626;
  border: 2px solid #c4c4c4;
  border-radius: 4px;
  p {
    margin: 0px;
  }
}

.form_textarea_prepend,
.form_textarea_append {
  padding-top: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #fff;
  transition: all 0.1s;
}

.has_prepend .form_textarea {
  padding-left: 0;
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.has_append .form_textarea {
  padding-right: 0;
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form_textarea_prepend {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form_textarea_append {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.error_txt {
  font-size: 13px;
  margin: 4px 0 0 0;
  color: #f00;
}

</style>
